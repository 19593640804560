import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import Header from '../../components/header'
import CharacterGrid from '../../components/charactergrid'
import InBrief from '../../components/inbrief'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight, ImageFull } from '../../components/imageelements'
import { SynopsisAct } from '../../components/synposisstyling'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import AudioSet from '../../components/audioset'
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import BlockQuote from '../../components/blockquote'
import VideoPlayer from '../../components/videoplayer'

const characters = [
  {name: 'Madame Larina', voice: 'Mezzo-soprano', desc: 'A widowed landowner and the mother of Tatyana and Olga. Kindly and caring.'},
  {name: 'Tatyana', voice: 'Soprano', desc: ' A young, innocent country girl who dreams of the bright lights of the city. The emotional core of the opera.'},
  {name: 'Olga', voice: 'Contralto', desc: 'Tatyana’s sister and Lensky’s Fiance. More important to the opera for her relationships than herself, Olga is young and pretty and she knows it.'},
  {name: 'Filippyevna', voice: 'Mezzo-soprano', desc: 'Tatyana’s nurse, nanny and confidante of sorts. Her major role is to deliver Tatyana’s letter to Onegin.'},
  {name: 'Lensky', voice: 'Tenor', desc: 'A poet, a dreamer and to his detriment a friend to Onegin. He is engaged to Olga.'},
  {name: 'Eugene Onegin', voice: 'Baritone', desc: 'The title role. Arrogant and effortlessly weary, his life consists of little but partying, yet he still finds the time to be bored.'},
  {name: 'Prince Gremin', voice: 'Bass', desc: 'Prince Gremin appears only briefly in Act III but gets one of the best arias in the whole opera. Married to, and desperately in love with, Tatyana.'},
  {name: 'Zaretsky', voice: 'Bass', desc: 'Lensky’s second for the duel. A much more significant figure than he might appear as he is the only person who could avert the duel in Act II (see the fun fact on duelling for more on this).'},
  {name: 'Triquet', voice: 'Tenor', desc: 'Tatyana’s old and slightly wobbly French tutor. He gets a brief moment to shine in his Act II song to Tatyana.'},
];

const inbrief = {name: 'Eugene Onegin', composer: "Pyotr Ilyich Tchaikovsky", librettist: "Tchaikovsky and Konstantin Shilovsky", language: "Russian", date: 'March 29th, 1879', acts: "Three", musiclength: "Two hours, thirty five minutes"};

const bibliography = [
  {name: 'Eugene Onegin: A Novel in Verse', writer: 'Alexander Pushkin', url: 'https://amzn.to/37O36jX'},
  {name: 'Tchaikovsky\'s Eugene Onegin: A Short Guide to a Great Opera', writer: 'Michael Steen', url: 'https://amzn.to/3snm0HW'},
  {name: 'The Birth of an Opera: Fifteen Masterpieces from Poppea to Wozzeck', writer: 'Michael Rose', url: 'https://amzn.to/3m6uPoj'},
  {name: 'The Tchaikovsky Handbook: A Guide to the Man and His Music: Catalogue of Letters, Genealogy, Bibliography', writer: 'Alexander Poznansky & Brett Langston', url: 'https://amzn.to/3yP553o'},
]

const TchaikovskyQuote = {content: 'It seems to me that I am truly gifted with the ability truthfully, sincerely, and to express the feelings, moods, and images suggested by a text. In this sense I am a realist and fundamentally a Russian.', quotee: 'Pyotr Ilyich Tchaikovsky'}
const TchaikovskyTatyanaQuote = {content: 'In my mind, this is all tied up with the idea of Tatyana, and it seemed to me that I myself had acted incomparably more basely than Onegin, and I became truly angry with myself for my heartless attitude towards this girl who was in love with me.', quotee: 'Pyotr Ilyich Tchaikovsky'}

// markup
const OneginPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Eugene Onegin | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas/onegin" />
          <meta name="description" content="A guide to Pyotr Ilyich Tchaikovsky's heartrending lyric opera, Eugene Onegin, including Synopsis, Music & Arias, Fun Facts, Running Time and much more!" />
        </Helmet>
        <Hero title="Eugene Onegin" subtitle="An Opera by Pyotr Ilyich Tchaikovsky"/>
        <MainBodySection>

          <BlockQuote quote={TchaikovskyQuote}/>

          <ImageFull>
            <StaticImage src="../../images/operas/onegin/Yevgeny_Onegin_by_Repin.jpg" alt="Eugene Onegin and Vladimir Lensky's duel by Ilya Repin" title="Eugene Onegin and Vladimir Lensky's duel by Ilya Repin"/>
            <figcaption>Eugene Onegin and Vladimir Lensky's duel by Ilya Repin</figcaption>
          </ImageFull>

          <p>Tchaikovsky’s <i>Eugene Onegin</i> is a radiant example of Russian Lyric Opera. A deeply moving tale packed with big tunes, dances and a heart-stopping duel. As with his ballets, Tchaikovsky brings many symphonic elements to the music, plush orchestrations and highly melodic arias.</p>

          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <p><i>Onegin</i> is a uniquely Russian opera with a universality that has made it globally popular. None of the characters are easy to associate with, yet the events and their actions ring horribly true. The piece provides opportunities for three great soloists as well as requiring top-drawer performances from the Chorus.</p>

          <AudioSet audiosrc="/audio/onegin/OneginIntro.mp3" desc="The very start of the opera and also the first appearance of the recurring 'Fate' Motif."/>

          <p>The work is subdivided into three acts, seven scenes and 22 numbers. Still, despite these clear divisions, Tchaikovsky advances a leitmotif-like structure spanning outward from Tatyana’s Letter Scene, the centrepiece of the opera. This isn’t Wagner by any means, but you’ll hear the same recurring themes throughout, ever-shifting towards the final climactic duet.</p>

          <Header title="In Brief"/>

          <InBrief content={inbrief}/>

          </MainBodySection>

  
          <div id="ezoic-pub-ad-placeholder-103"> </div>


          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>

            <Header title="Characters"/>

            <CharacterGrid rows={characters}/>

            <Header title="Synopsis"/>

            <SynopsisAct>Act I - Running Time: 75 mins</SynopsisAct>

            <h4>Scene 1</h4>

            <p>We find ourselves at the country estate of Madam Larina, a middle-aged widow with two daughters, Olga and Tatyana. Think Chekhov country, beautiful spring surroundings but exceedingly dull for young Russian people.</p>

            <p>Larina and the nurse Filippyevna are sitting outside. They can hear the young girls singing, and Larina recalls her own young courtship and marriage. The peasants who work on the estate enter singing a big harvest song that marks the first big dance of the opera.</p>

            <p>Tatyana is lost in a romance novel while her younger sister Olga wants to join the harvest festivities. Larina tries to tell Tatyana that life isn’t like a novel, but Tatyana is a young dreamer and doesn’t pay much attention.</p>

            <p>Visitors have arrived. It is Lensky, Olga’s fiance, and his friend Onegin. They enter, and quickly Tatyana reveals herself attracted to Onegin. Onegin for himself questions why Lensky would choose Olga over her sister. Lensky, on the other hand, delights in Olga’s flirtations.</p>

            <p>Onegin takes a walk with Tatyana describing his boredom in the countryside (he’s a model of Russian dissatisfaction). Tatyana is unsure how to respond as they go inside for dinner.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/onegin/On&Tat.jpg" alt="Onegin and Tatyana from a 1908 edition of Onegin"/>
              <figcaption>Onegin and Tatyana from a 1908 edition of Onegin</figcaption>
            </ImageFull>

            <h4>Scene 2</h4>

            <p>We’re in Tatyana’s bedroom. She pesters Filippyevna to tell her of her youthful loves and marriage. Tatyana states she is in love and asks to be left alone.</p>

            <p>Here begins one of the truly great operatic monologues: Tatyana’s Letter Scene. Through her fevered passion, she writes a letter to Onegin revealing the force of her love.</p>

            <p>Day breaks, and she gives the letter to Filippyevna.</p>

            <VideoPlayer src="https://www.youtube.com/embed/Ka4GsQHTXSA?autoplay=1" id="Ka4GsQHTXSA" title="Kristine Opolais sings Tatyana's Letter Scene"/>

            <h4>Scene 3</h4>

            <p>Outside again, Tatyana awaits Onegin. He arrives and tells her his feelings. He tells her, not altogether unfairly or unkindly, that he is no man for marriage and would be bored by it. He is not worthy of such love and can only offer her friendship. More icily, he suggests she not be so open in future lest other men take advantage.</p>

            <p>Unable to reply to this, the curtain falls on a distraught Tatyana.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-104"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act II - Running Time: 45 mins</SynopsisAct>

            <h4>Scene 1</h4>

            <p>Three months have passed. It is Tatyana’s name day (a curious tradition similar to a birthday but held on the day your name is associated with) and a country ball is being held in her honour. We hear a fabulous waltz and Onegin dances with Tatyana. Naturally, he is bored and quickly grows irritated by those gossiping around him. Blaming Lensky for bringing him, he decides to flirt with Olga.</p>

            <AudioSet audiosrc="/audio/onegin/waltz.mp3" desc="The Act II Waltz"/>

            <p>Olga reciprocates Onegin’s attention, and Lensky loses his cool, jealously accusing Olga. Olga cannot see what she has done wrong, and Onegin further pushes the situation by dancing with her again. The situation is temporarily defused when Monsieur Triquet, Tatyana’s French tutor, sings a brief song. As the dancing resumes, however, it all kicks off.</p>

            <p>Lensky, in front of all the guests, renounces his friendship with Onegin and impetuously challenges him to a duel. Onegin has no choice but to accept (see the fun facts for more on the mindboggling Russian affection for duelling). Tatyana faints as the ball ends in disaster.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/onegin/Eugene_Onegin_ENO_2011_Audun_Iversen_and_Toby_Spence_Credit_Neil_Libbert.jpg" alt="Lensky (Toby Spence) challenges Onegin (Audun Iversen) &copy; Neil Libbert/ENO"/>
              <figcaption>Lensky (Toby Spence) challenges Onegin (Audun Iversen) &copy; Neil Libbert/ENO</figcaption>
            </ImageFull>

            <h4>Scene 2</h4>

            <p>We are by the riverbank the following morning. Lensky awaits Onegin, reflecting on his daft decision to challenge Onegin (he well knows that he will almost certainly lose and won’t be much happier if he kills his friend either). Onegin arrives late, and both raise the fact that they have absolutely no wish to go through with the duel.</p>

            <p>It is too late for taking things back. The duel begins, and Onegin kills Lensky.</p>

          </MainBodySection>


          <div id="ezoic-pub-ad-placeholder-105"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act III - Running Time: 35 mins</SynopsisAct>

            <h4>Scene 1</h4>

            <p>The action leaps forward years, and we are now in St Petersburg. A grand ball is taking place, and here we find Onegin, who predictably is bored. He has spent years travelling around Europe and now reflects on the emptiness of life (particularly his own).</p>

            <AudioSet audiosrc="/audio/onegin/polonaise.mp3" desc="The Act III Polonaise"/>

            <p>Prince Gremin enters with his gorgeous young wife. Shock, horror: it is Tatyana now regal, mature and beautiful. Onegin is taken aback, as is Tatyana, when she catches sight of him. Gremin tells Onegin of his happiness with his loving wife and introduces her to him (unaware of their past!).</p>

            <p>Onegin suddenly realises he is in love with Tatyana and decides to write to her and arrange a private meeting. Oh, how the tables have turned!</p>

            <h4>Scene 2</h4>

            <p>Tatyana has received a love letter from Onegin. We now find her in Gremin’s house, waiting for his arrival. She cannot work out why he has changed. Is it her money and status? He enters and denies all cynical motives. He claims to love her deeply.</p>

            <p>This moves Tatyana greatly, but she asks him to leave. Onegin begs, but Tatyana explains that it is impossible though she still loves him; her loyalty must lie with Gremin. She departs tearfully. Onegin is left alone.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-106"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="History"/>

            <p>During the latter half of 1876 and into 1877, Tchaikovsky sought a topic for his next opera. The suggestion for “Eugene Onegin”, Pushkin’s poetic masterpiece, came to Tchaikovsky when he was visiting friends, an idea that at first struck him as crazy (and struck many critics in the same way even after they’d heard the opera!). The story lacked plot, the situations bland and the conclusion without a climax (not to mention the impossibility of capturing on stage one of the novel’s most powerful elements: the self-conscious, first-person narrator).</p>

            However, <p>He quickly fell head over heels for the tale and swiftly set to work creating a scenario with Konstantin Shilovsky, the co-librettist. This everyday story was a novelty in the world of opera, but Tchaikovsky wrote to his brother, Modest, in May 1877, “How glad I am to be rid of Egyptian princesses, pharaohs, poisonings, and stilted effects of all kinds. What a mine of poetry there is in Onegin”. The opera would come together over the next two years, but all was not plain sailing despite Tchaikovsky’s strong drive.</p>

            <BlockQuote quote={TchaikovskyTatyanaQuote}/>

            <ImageRight>
              <StaticImage src="../../images/operas/onegin/TchaikandMilk-small.jpg" alt="Tchaikovsky and Antonina Milyukova"/>
              <figcaption>Tchaikovsky and Antonina Milyukova</figcaption>
            </ImageRight>

            <p>Where Pushkin associated himself with Onegin, Tchaikovsky fell for Tatyana. Around the time Tchaikovsky began feverish work on <i>Onegin</i> he received a love letter from a long-forgotten student named Antonina Milyukova, not unlike that sent by Tatyana to Onegin. Tchaikovsky was a closet homosexual and had little interest in Milyukova, but fearing himself acting much like the vile Onegin, he ended up marrying her!</p>

            <p>Lest we get too washed up in Romanticism, it is also true that Tchaikovsky feared his homosexuality becoming public and using marriage as a cover was no doubt also on his mind. In any case, the whole enterprise was a total disaster for all involved. They lasted together for six weeks before Tchaikovsky tried to drown himself and then fled with his brother to St Petersburg. Tchaikovsky and Milyukova would never divorce, though they rarely met, and poor Milyukova spent the last twenty years of her life in a mental asylum.</p>

            <p>After these traumas, Tchaikovsky would progress steadily with work on <i>Onegin</i> first in Switzerland and then Italy, where he finished the opera in January 1878. Tchaikovsky was proud of the work but feared what might happen if it were given to one of the grand opera houses. He wasn’t even sure it should be an “opera” thinking of it as “lyrical scenes”. As a result, he gave the students of the Moscow Conservatory the opportunity to premiere <i>Onegin</i> which they did in March 1878, conducted by Nikolai Rubinstein.</p>

            <p>After several years of tinkering by Tchaikovsky, particularly in regards to the final scene, <i>Onegin</i> received its professional debut at the Bolshoi Theatre in January 1881. Initial reactions were mixed; many struggled with the very concept of Pushkin’s poetry being manipulated despite the supreme quality of the music. Internationally the opera was performed sporadically, Gustave Mahler conducted the German premiere in 1892. It reached England the same year. However, it would take till 1920 for it to first reach the USA.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-107"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Fun Facts"/>

            <FunFactsHeader>Poetry</FunFactsHeader>

            <p>Pushkin’s “Onegin” is of note for many reasons, but its unusual form is of particular interest (and of particular difficulty for anyone attempting a translation). The book is written almost exclusively in stanzas of iambic tetrameter with the rhyme scheme "AbAbCCddEffEgg".</p>

            <p>The uppercase letters represent feminine rhymes (A rhyme on two syllables, e.g. passion and fashion), while the lowercase letters represent masculine rhymes (One syllable, e.g. head and bed). This form is often called the "Onegin stanza".</p>

            <FunFactsHeader>Ballet</FunFactsHeader>

            <p>John Cranko made a narrative ballet entitled “Onegin”, which closely tracks the episodes of Tchaikovsky’s opera (as opposed to an adaptation from the original text). The score for this ballet is also entirely made up of Tchaikovsky music but interestingly uses nothing from <i>Eugene Onegin</i>. The arranged score, by Kurt-Heinz Stolze, mixes and matches from all over Tchaikovsky’s oeuvre, including from his much less well-known opera <i>Cherevichki</i> (sometimes called <i>The Tsarina’s Slippers</i>).</p>

            <FunFactsHeader>Pushkin's operas</FunFactsHeader>

            <p>Though many considered Tchaikovsky’s attempt at turning Pushkin’s “Onegin” into an opera foolish (the plot didn’t fit with the conventions of 19th Century opera), Pushkin’s works have formed the basis for more than a dozen significant operatic works. Tchaikovsky himself returned with <i>Mazeppa</i> and <i>The Queen of Spades</i> while we also have <i>Boris Godunov</i> by Modest Mussorgsky, <i>Ruslan and Lyudmila</i> by Mikhail Glinka and <i>The Golden Cockerel</i> by Nikolai Rimsky-Korsakov.
            </p>

            <FunFactsHeader>Duels</FunFactsHeader>

            <p>Dueling in Russia was a wildly popular activity, despite having been banned in 1715 by the Emperor because his army kept slaughtering each other (though the threat of hanging can’t have been much of a deterrent considering one of the participants often died anyway). Duels were highly formalised, and it is these rules that eventually force Onegin to shoot despite all his wishes against it. He cannot apologise without first being asked by Lensky’s second, Zaretsky, and as this never comes, they are “forced” to duel.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/onegin/afterduel.jpg" alt="After the Duel, Ilya Repin" title="After the Duel, Ilya Repin"/>
              <figcaption>After the Duel, Ilya Repin</figcaption>
            </ImageFull>

            <p>These duelling practices may seem insane, but Pushkin himself was more than a bit fond of duelling. He was involved in nearly 30 throughout his life, eventually done in by a bullet to the spleen during a duel with Georges d’Anthès.</p>

              <div id="ezoic-pub-ad-placeholder-108"> </div>
  
            <FunFactsHeader>Ending</FunFactsHeader>

            <p>How to end Onegin proved one of the trickiest elements for Tchaikovsky. Not long before the opera's completion, Tatyana still fell into the arms of Onegin only to be reminded of her fealty by the entrance of Gremin. As you might imagine, many were not so impressed to see their heroine still succumbing, and Tchaikovsky was encouraged to shift the tone towards Tatyana resolving her situation without help.</p>

            <p> How Onegin responded varied too, shifting from 'O death, o death! I go to seek thee out!' to ‘Ignominy! ... Anguish! … Oh, my pitiable fate!’. The version we hear today is somewhere between the two (and hinges heavily on how the director wants to stage things) though Onegin’s latter line is usually the one heard.</p>

            <FunFactsHeader>Clarens</FunFactsHeader>

            <ImageRight>
              <StaticImage src="../../images/operas/onegin/nabokov.jpg" alt="The Grave of Nabokov at Clarens"/>
              <figcaption>The Grave of Nabokov at Clarens</figcaption>
            </ImageRight>

            <p>The village of Clarens, by Lake Geneva in Switzerland, was the place where Tchaikovsky fled with his brother. His mental health was in tatters after his disastrous marriage, and the lake air was supposed to be beneficial. Here he wrote a good chunk of the orchestrations for <i>Onegin</i> as well as several other pieces.</p>

            <p>This wasn’t Clarens’ only contribution to the musical world as it was also the place where Igor Stravinsky wrote “The Rite of Spring” and “Pulcinella”. What’s more, Vladimir Nabokov was buried at Clarens. A remarkable confluence of artists that few villages could compete with!</p>

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default OneginPage
